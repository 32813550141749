<template>
  <div class="loginbox">
    <div v-show="firstShow">
      <div class="header">登录/注册</div>
      <div class="box">
        <div class="title">
          <!-- <img src="../assets/img/logo.jpg"> -->
          <p>欢迎来到恒哲</p>
        </div>
        <el-input
          placeholder="请输入您的手机号"
          v-model="phone"
          clearable
          type="tel"
          @keyup.native="phoneKey"
          @blur="handleBlur()"
          maxlength="13">
        </el-input>
        <span :class="className" @click="getImg">获取验证码</span>
      </div>
    </div>
    <div v-show="secondShow" class="secondBox">
      <div class="ImgBox">
        <div class="title">请输入图形验证码</div>
        <div class="box1">
          <el-col :span="14">
            <el-input
              placeholder="图形验证码"
              v-model="imgCode"
              clearable
              type="tel"
              @blur="handleBlur()"
              maxlength="4">
            </el-input>
          </el-col>
          <el-col :span="10">
            <img :src="vcodeImg" alt="" @click="getImg">
          </el-col>
        </div>
        <p class="error">{{ error }}</p>
        <div class="btn">
          <span @click="ImgClose">取消</span>
          <span @click="ImgConfim">确认</span>
        </div>
      </div>
    </div>
    <div id="codeDiv"  v-show="phoneCodeShow">
      <div class="header">{{ headerName }}</div>
      <div class="box">
        <input @keyup="codeKey" maxlength="6" v-model="code" type="tel" id="code" autocomplete="off" ref="code">
        <ul>
          <li>&nbsp;</li>
          <li>&nbsp;</li>
          <li>&nbsp;</li>
          <li>&nbsp;</li>
          <li>&nbsp;</li>
          <li>&nbsp;</li>
        </ul>
        <div class="codebtn">
          <!-- <span @click='getCode("AUDIO",1)'>{{ timesAudio }}</span> -->
          <span @click='getCode("SMS",1)'>{{ times }}</span>
        </div>
        <p class="error">{{ error }}</p>
        <span :class="className1" @click="login">{{ btnName }}</span>
        <div v-show="xieyiFlag" class="xieyi">
          <input type="checkbox" checked="checked" id="xieyi" @click="checkXieyi">
          <i>{{ checkText }}</i>
          <b>我已同意并阅读</b>  
          <router-link :to="{path:'privacySettings'}" exact> 
            <span>《用户服务协议》《隐私权政策》等协议</span>
          </router-link>
        </div>
      </div>
      
    </div>
    
  </div>
</template>
<script>
import { getPublicKey,checkUserExist,sendSmsBeforLogin,captcha,register,setLoginPwd,login} from "../services/api";
import $ from 'jquery'
import basic from '../utils/basic';

export default {
  data() {
    return {
      phone: "",
      className: "btn",
      className1: "btn",
      className2: "btn",
      loginFlag: false,
      codeFlag: false,
      vcodeImg: "",
      picCodeKey: "",
      imgCode: "",
      firstShow: true,
      secondShow: false,
      thirdShow: false,
      fourthShow: false,
      error: "",
      times: "获取短信验证码",
      timesAudio: "获取语音验证码",
      canSend: true,
      code: "",
      headerName: "验证码登录",
      btnName: "登录",
      xieyiFlag: false,
      isShowX: false,
      pwd: "",
      pwdFlag: false,
      key: "",
      isExist: "",
      codeType: "",
      pwderror: "",
      isDisplayPicCode: "",
      href: { path: 'loginPwd' },
      jroiqohoi: '',
      mobilePhone: '',
      checked: true,
      checkText: '√',
      // procotolist: [],
      // procotolist2: [],
      // listShow: false,
      // listShow2: false,
      agreeContentShow: false,
      agreeContent: {},
      newAgreementBoxShow: true,
      protocolNoStr: '',
      phoneCodeShow: false
    };
  },
  components: { //xieyiContent
   },
  watch: {
    phone(newValue, oldValue) {
      if (newValue > oldValue) {
        if (newValue.length === 4 || newValue.length === 9) {
          var pre = newValue.substring(0, newValue.length - 1);
          var last = newValue.substr(newValue.length - 1, 1);
          this.phone = pre + ' ' + last;
        } else {
          this.phone = newValue;
        }
      } else {
        if (newValue.length === 9 || newValue.length === 4) {
          this.phone = this.phone.trim();
        } else {
          this.phone = newValue;
        }
      }
    }
  },
  methods: {
    getQueryString(key) {
      var after = window.location.hash.split("?")[1];
      if (after) {
        var reg = new RegExp("(^|&)" + key + "=([^&]*)(&|$)");
        var r = after.match(reg);
        if (r != null) {
          return decodeURIComponent(r[2]);
        } else {
          return null;
        }
      }
    },
    compFina() {
      var _this = this;
      _this.$router.push("/compLogin");
    },
    phoneKey() {
      let ter = /^\d{11}$/;
      if (this.phone.length == 13) {
        this.className = 'btn sub';
        console.log("----")
        this.href = {
          path: 'loginPwd',
          query: {
            // wechat: this.$route.query.wechat,
            // redirectURL: this.$route.query.redirectURL,
            phone: this.phone.replace(/\s+/g, ""),
            // flag: this.$route.query.flag,
            // bankFrom: this.$route.query.bankFrom
          }
        };
      } else if (ter.test(this.phone)) {
        var tel = this.phone;
        this.phone = tel.substring(0, 3) + ' ' + tel.substring(3, 7) + ' ' + tel.substring(7, 11);
        this.className = 'btn sub';
        this.href = {
          pathpath: 'loginPwd',
          query: {
            // wechat: this.$route.query.wechat,
            // redirectURL: this.$route.query.redirectURL,
            phone: this.phone.replace(/\s+/g, ""),
            // flag: this.$route.query.flag,
            // bankFrom: this.$route.query.bankFrom
          }
        };
      } else {
        this.className = 'btn';
      }
    },
    handleBlur() {
      window.scroll(0, 0);
    },
    codeKey() {
     
      this.code = this.code.replace(/[^\d]/g, '');
      for (var i = 0; i < $("li").length; i++) {
        if (this.code[i] == undefined) {
          $("li").eq(i).html("&nbsp;");
        } else {
           $("li").eq(i).html(this.code[i]);

        }

      }
      if (this.code.length == 6 && this.checked == true) {
        this.className1 = 'btn sub';
        this.codeFlag = true;
        this.loginFlag = true;
      } else {
        this.className1 = 'btn';
        this.codeFlag = false;
        this.loginFlag = false;
      }
    },
    pwdKey() {
      if (this.pwd.length >= 6) {
        this.className2 = 'btn sub';
        this.pwdFlag = true;
      } else {
        this.className2 = 'btn';
        this.pwdFlag = false;
      }

    },

    // 获取图形验证码
    getImg() {
      var _this = this;
      _this.$refs.code.focus();
      _this.error = "";
      var phone = _this.phone.replace(/\s+/g, "");
      if (basic.phoneTest(phone)) {
        var jsencrypt = new JSEncrypt();
        jsencrypt.setPublicKey(_this.key);
        var params = jsencrypt.encrypt('{"mobilePhone":"' + phone + '"}');
        _this.checkUserExist();
      } else {

      }
    },
     async getPublicKey() {//获取开启加密
      const data = await getPublicKey({});
      if (data.code === 1) {
        this.key = data.data.data;
      } else {
        this.$toast(msg);
      }
    },
    async captcha() {//图形验证码
      var _this=this;
      var jsencrypt = new JSEncrypt();
      jsencrypt.setPublicKey(this.key);
      var params = jsencrypt.encrypt('{"mobilePhone":"' + _this.phone + '"}');
      const datas = await captcha({ params: params,type:1 });
      if (datas.code === 1) {
          var _data = datas.data;
          var vcode = 'data:image/png;base64,' + _data.cs;
          _this.vcodeImg = vcode;
          _this.picCodeKey = _data.ts;
          _this.imgCode = "";
          _this.firstShow = false;
          _this.thirdShow = false;
          _this.secondShow = true;
      } else {
        _this.$toast(msg);
      }
    },
    async checkUserExist() {//
      const _this=this;
      console.log( _this.phone)
      var phone = _this.phone.replace(/\s+/g, "");
      var jsencrypt = new JSEncrypt();
      jsencrypt.setPublicKey(_this.key);
      const params = jsencrypt.encrypt('{"mobilePhone":"' + phone + '"}');
      console.log(phone)
      console.log(params+'====')
      const data = await checkUserExist({ params: params, type: 1});
      if (data.code == 1) {
        var datas = data.data;
        if (datas.isExist == 0) {
          _this.headerName = "注册";
          _this.btnName = "注册";
          _this.xieyiFlag = true;
        }
        _this.isExist = datas.isExist;
        _this.isDisplayPicCode = datas.isDisplayPicCode;
        if (datas.isDisplayPicCode == 0) {   //不显示图像验证码
          _this.firstShow = false;
          // _this.thirdShow=true;
          _this.getCode("SMS", 0);

        } else if (datas.isDisplayPicCode == 1) {   //显示图形验证码
          //_this.$refs.code.blur();
          _this.phoneCodeShow = false;
          _this.captcha();

        }
      }
    },
    // 关闭图形验证码弹窗
    ImgClose() {
      this.firstShow = true;
      this.secondShow = false;
    },
    ImgConfim() {
      var _this = this;
      if (_this.imgCode == "") {
        _this.error = "请输入图形验证码";
      } else if (_this.imgCode.length != 4) {
        _this.error = "请输入正确的图形验证码";
      } else {
        _this.$refs.code.focus();

        _this.error = "";
        if (_this.codeType == "") {
          if (_this.isExist == 0) {
            _this.getCode('SMS', 0);
          } else if (_this.isExist == 1) {
            _this.getCode('SMS', 0);
          }
        } else {
          _this.getCode(_this.codeType, 0);
        }

      }
    },
    // 获取短信验证码
    async getCode(name, type) {
      var _this = this;

      if (_this.canSend == true) {

        if (_this.isExist == 0) {    //注册验证码
          var smsType = 'REGISTER_' + name;
        } else if (_this.isExist == 1) {   //登录验证码
          var smsType = 'QUICK_LOGIN_' + name;
        }
        if (type == 1) {
          _this.codeType = name;
          _this.imgCode = "";
          if (_this.isDisplayPicCode == 1) {
            _this.getImg();
          } else {
            _this.getCode(name, 0);
          }
        } else if (type == 0) {
          _this.canSend = false;
         var jsencrypt = new JSEncrypt();
          jsencrypt.setPublicKey(_this.key);
          var phone = _this.phone.replace(/\s+/g, "");
          var params = jsencrypt.encrypt('{"mobilePhone":"' + phone + '"}');
          const datas =await sendSmsBeforLogin({ 
            params: params,
            smsType:smsType,
            picCodeKey:_this.picCodeKey,
            picCode:_this.imgCode});
          //const datas={"code":1,"data":{},"msg":"发送成功"}
          if (datas.code === 1) {
            // _this.thirdShow = true;
            _this.secondShow = false;
            _this.phoneCodeShow = true;
            // _this.$refs.code.focus()
            //document.getElementById("codeDiv").style.opacity = "inherit";
            var myTime = 120;
            if (name == "SMS") {
              //倒计时
              var timer = setInterval(function() {
                if (myTime == 1) {
                  clearInterval(timer);
                  _this.times = '获取短信验证码';
                  _this.canSend = true;
                } else {
                  myTime--;
                  _this.times = myTime + 's后重新获取';
                }
              }, 1000);
              console.log(_this.times)
            } else if (name = "AUDIO") {
              //倒计时
              var timer = setInterval(function() {
                if (myTime == 1) {
                  clearInterval(timer);
                  _this.timesAudio = '获取语音验证码';
                  _this.canSend = true;
                } else {
                  myTime--;
                  _this.timesAudio = myTime + 's后重新获取';
                }
              }, 1000);
            }
          } else {
            this.$toast(msg);
          }
        }
      }
    },
    //登录
    checkXieyi() {
      var _this = this;
      if (_this.checked == true) {
        _this.checked = false;
        _this.checkText = '';
        _this.className1 = 'btn';
        _this.loginFlag = false;
      } else if (_this.checked == false) {
        _this.checked = true;
        _this.checkText = '√';
        if (_this.code.length == 6) {
          _this.className1 = 'btn sub';
          _this.loginFlag = true;
        }
      }
    },
    async login() {
      var _this = this;
      if (_this.loginFlag == true) {
        _this.loginFlag = false;
        if (_this.btnName == "登录") {
          var userName, code, params;
          if (_this.key == "") {
            userName = _this.phone.replace(/\s+/g, "");
            code = _this.code;
            params = { userName: userName, code: code };
          } else {
            var jsencrypt = new JSEncrypt();
            jsencrypt.setPublicKey(_this.key);
            userName = _this.phone.replace(/\s+/g, "");
            params = jsencrypt.encrypt('{"userName":"' + userName + '","code":"' + _this.code + '"}');
          }
          const datas =await login({ params: params,wechat: _this.$route.query.wechat });
          if (datas.code === 1) {
              console.log('登录数据', datas.data);
              // _this.jroiqohoi = datas.data.jroiqohoi;
              // _this.mobilePhone = datas.data.mobilePhone;
              // _this.jwtToken = datas.data.jwtToken;

              // //缓存起来，后续使用
              localStorage.setItem('jroiqohoi', datas.data.jroiqohoi);
              localStorage.setItem('jwtToken', datas.data.jwtToken);
              localStorage.setItem("mobilePhone", datas.data.mobilePhone);
              localStorage.setItem('userId', datas.data.user_id);
              _this.$router.push({
                path: '/productList',
              });
          } else {
            this.$toast(msg);
          }
        } else if (_this.btnName == "注册") {
          var userName, code, params;
          if (_this.key == "") {
            userName = _this.phone.replace(/\s+/g, "");
            code = _this.code;
            params = { userName: userName, code: code };
          } else {
            var jsencrypt = new JSEncrypt();
            jsencrypt.setPublicKey(_this.key);
            userName = _this.phone.replace(/\s+/g, "");
            params = jsencrypt.encrypt('{"mobilePhone":"' + userName + '","code":"' + _this.code + '"}');
          }

           var _this=this;
          const datas= await register({ params: params, role: 1, userNature: 1, wechat: _this.$route.query.wechat });
          if (datas.code === 1) {
                _this.phoneCodeShow = false;
                _this.thirdShow = false;
                _this.fourthShow = true;
                // _this.jroiqohoi = datas.data.jroiqohoi;
                // _this.mobilePhone = datas.data.mobilePhone;
                // _this.jwtToken = datas.data.jwtToken;
               // _this.signProcotol("Z");
                //缓存起来，后续使用
              localStorage.setItem('jroiqohoi', datas.data.jroiqohoi);
              localStorage.setItem('jwtToken', datas.data.jwtToken);
              localStorage.setItem("mobilePhone", datas.data.mobilePhone);
              localStorage.setItem('userId', datas.data.userId);
              _this.$router.push({
                path: '/productList',
              });
          } else {
            this.$toast(datas.msg);
          }

        }
      }

    },
    close() {
      this.isShowX = false;
    },
   
    async setPwd() {
      var _this = this;
      if (_this.pwdFlag == true) {
        if (basic.passwordTest(_this.pwd) == false) {
          _this.pwderror = "密码由6-16位数字及字母组成";
        } else if (basic.passwordTest(_this.pwd) == true) {

          _this.pwdFlag = false;
          var jsencrypt = new JSEncrypt();
          jsencrypt.setPublicKey(_this.key);
          var password = md5(_this.pwd);
          var params = jsencrypt.encrypt('{"password":"' + password + '"}');
          const data =await setLoginPwd({ params: params });
          if (data.code === 1) {
            if (type == 'D') {
                 _this.loginLink();
              }
          } else {
            this.$toast(msg);
          }

        }
      }
    },

    // 提示框
    openBox(msg) {
      const h = this.$createElement;
      this.$notify({
        showClose: false,
        duration: 3000,
        message: h('i', { style: 'color: #fff' }, msg)
      });
    },

    // 与其他系统的登录
    loginLink() {
      //location.href = this.getRedirectUrl(this.jroiqohoi);
    },
    getList() {
      let _this = this;
      //获取注册协议列表
      // $.ajax({
      //   url: '/api/procotolist',
      //   dataType: 'json',
      //   type: 'get',
      //   data: { protocolFunction: "A" },
      //   success: function(datas) {
      //     if (datas.code == 1) {
      //       _this.procotolist = datas.data.result;
      //     }
      //   }
      // });
    },
    getDetail(protocolNo) {
      let _this = this;
      // $.ajax({
      //   url: '/api/procotolDetail',
      //   dataType: 'json',
      //   type: 'get',
      //   data: { protocolNo: protocolNo },
      //   success: function(datas) {
      //     if (datas.code == 1) {
      //       _this.agreeContent = datas.data.result;
      //       _this.agreeContentShow = true;

      //     }
      //   }
      // });
    },
    agreeContentHide() {
      this.agreeContentShow = false;
    },
    listHide() {
      this.listShow = false;
    },
    listHide2() {
      this.listShow2 = false;
    },
    // signProcotol(type) {
    //   let _this = this;
    //   // var jsencrypt = new JSEncrypt();
    //   // jsencrypt.setPublicKey(this.key);
    //   var params = {"mobilePhone":'130000000'};
    //   // const { status, data } = await signProcotol({ protocolFunction: 'A' });
    //   // const { code, data, msg } = data;
    //   const data={"code":1,"data":{"result":[{"createTime":1592382479000,"delFlag":"N","id":48,"protocolFunction":"A","protocolName":"《碧享购隐私权政策》","protocolNo":"XY_292222022452350976","protocolType":"B","protocolVersion":"V1.0.5","status":"Y","updateTime":1592382479000}]},"msg":"成功"}
    //   //if (+code === 1) {
    //      if (type == 'D') {
    //         _this.loginLink();
    //       }
    //   // } else {
    //   //   this.$toast(msg);
    //   // }
     
    // },
    // newProcol(type, protocolNoStr = '') {
    //   let _this = this;
    //    // var jsencrypt = new JSEncrypt();
    //   // jsencrypt.setPublicKey(this.key);
    //   var params = {"mobilePhone":'130000000'};
    //   // const { status, data } = await newProcol({ protocolFunction: 'A' });
    //   // const { code, data, msg } = data;
    //   const data={"code":1,"data":{"result":[{"createTime":1592382479000,"delFlag":"N","id":48,"protocolFunction":"A","protocolName":"《碧享购隐私权政策》","protocolNo":"XY_292222022452350976","protocolType":"B","protocolVersion":"V1.0.5","status":"Y","updateTime":1592382479000}]},"msg":"成功"}
    //   //if (+code === 1) {
    //     if (datas.data.result.length > 0) {
    //       _this.procotolist2 = datas.data.result;
    //       _this.newAgreementBoxShow = true;
    //       let protocolNoStr = datas.data.result.map(function(item) {
    //         return item.id;
    //       });
    //       _this.protocolNoStr = protocolNoStr.join(',');
    //     } else {
    //       _this.loginLink();
    //     }
    //   // } else {
    //   //   this.$toast(msg);
    //   // }
    // },
    logout() {
      let _this = this;
      let storage = window.localStorage;
      storage.setItem("isSms", "0");
      _this.newAgreementBoxShow = false;
      _this.$router.go(0);
    },
    // listShowFun2() {
    //   //this.listShow2 = true
    //   window.location.href = '/privacySettings';
    // }

  },
 async created() {
   this.getPublicKey();
  }
};
</script>
<style lang="scss" scoped>

</style>
