export default {
  checkNum(rule, value, callback) {
    var patrn = /^[0-9]*$/;
    if (!patrn.test(value) || value == "") {
      return callback(new Error('请输入数字'));
    } else {
      callback();
    }

  },
  checkIdCard(value) {
    var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;

    if (reg.test(value) === false) {
      return false;
    } else {
      return true;
    }
  },
  checkPhone(rule, value, callback) {
    var reg = /^[1][0-9]{10}$/;
    if (reg.test(value)) {
      callback();
    } else if (value == "") {
      return callback(new Error('请输入手机号'));
    } else {
      return callback(new Error('手机号不正确'));
    }

  },
  checkRequire(rule, value, callback) {

    if (value && value != "") {
      callback();
    } else {
      return callback(new Error('口令不能为空'));
    }
  },
  checkBankNum(rule, value, callback) {
    var patrn = /^[0-9]*$/;
    if (value == "") {
      return callback(new Error('请输入银行卡号'));
    } else if (!patrn.test(value)) {
      return callback(new Error('银行卡号不正确'));
    } else {
      callback();
    }

  },
  //随机串base64加密
  generateMixed(n) {
    var jschars = ['1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'h', 'j', 'm', 'n', 'r', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

    var res = "";
    for (var i = 0; i < n; i++) {
      var id = Math.ceil(Math.random() * 44);
      res += jschars[id];
    }
    return res;
  },
  //手机号格式
  phoneTest(value) {
    var reg = /^1\d{10}$/;
    if (!reg.test(value) || value.length != 11) {
      return false;
    } else {
      return true;
    }
  },
   //密码格式
   passwordTest(value) {
    var reg = /^(?![^a-zA-Z]+$)(?!\D+$).{6,16}$/;
    if (!reg.test(value)) {
      return false;
    } else {
      return true;
    }
  },
  //时间格式化
  formatDateNew(date) {
    var d = new Date(date);
    return d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
  },
  isEmail(mail) {
    var filter = /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/;
    if (filter.test(mail)) {
        return true;
    } else {
        return false;
    }
  },
  isName(name) {//校验账户名
    var isReg = (
        /^[\u4e00-\u9fa5]|[a-zA-Z]$/.test(name) &&
        /^(?![·\-_\s])(?!.*[·\-_\s]$)/.test(name) &&
        /^[·\-\s\w\u4e00-\u9fa5]*$/.test(name) &&
        !/[0-9]/.test(name) &&
        !/[\u4e00-\u9fa5][a-zA-Z]{1}|[a-zA-Z][\u4e00-\u9fa5]{1}/.test(name) &&
        !/[·_\-\s]{2}/.test(name) &&
        !/[\u4e00-\u9fa5]\s[\u4e00-\u9fa5a]/.test(name) &&
        !/[a-z]\s{2,}[a-z]/i.test(name)
    );
    return isReg;
  },
  isChinese(str){
    var han = /^[\u4e00-\u9fa5]+$/;
    if (!han.test(str)) {
          return false;
    };
    return true;
  },
  isNum(str){
    var t = /^[0-9]*$/;
    if (!t.test(str)) {
          return false;
    };
    return true;
  },


};

